<template>
	<div>
		<div class="IN-top">
			<div class="w " style="font-size: 36px;color: #DA1010;cursor: pointer;" @click="back()">
				商家入驻
			</div>
		</div>
		<div class="texts w">
			您的申请已提交，我们会在3个工作日之内与您联系。
		</div>
		<div class="buttom" @click="dian()">确认</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			dian(){
				this.$router.push({
					path:'/'
				})
			}
		}
	}
</script>

<style lang="less">
	page{
		background-color: #F8F8F8;
	}
	.w{
		width: 1200px;
		margin: auto;
		position: relative;
	}
	.IN-top{
		//width: 1920px;
		height: 166px;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.texts{
		margin-top: 400px;
		font-size: 40px;
		text-align: center;
	}
	.buttom{
		position: absolute;
		right :0px;
		top:800px;
		width: 200px;
		height: 60px;
		line-height: 60px;
		text-align: center;
		background: #DA1010;
		border-radius: 10px;
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FEFEFE;
		cursor: pointer;
	}
</style>